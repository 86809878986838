@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* || VARIABLES */

:root {
    /* COLORS */
    --BGCOLOR: #0F172A;
    --BORDER-COLOR: #FFF;
    --COLOR: #FFF;
    --ERROR: firebrick;
    --INACTIVE: lightgray;
    --LINK-COLOR: whitesmoke;
    --LINK-HOVER: rgba(245, 245, 245, 0.9);
    --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
    --STATUS-ACTIVE: limegreen;
    --STATUS-INACTIVE: #F00;
    --TABLE-BGCOLOR: #FFF;
    --TABLE-COLOR: #000;
    --INPUT-INVALID: #F00;
}

/* || UTILITY CLASSES */
.errmsg {
    display: inline-block;
    background-color: var(--TABLE-BGCOLOR);
    color: var(--ERROR);
    padding: 0.25em;
    margin-bottom: 0.5em;
}

.errmsg a:any-link {
    color: var(--ERROR);
    text-decoration: underline;
}

.nowrap {
    white-space: nowrap;
}

.icon-button {
    width: 48px;
    height: 48px;
    font-size: 2.5rem;
    background-color: transparent;
    border: none;
    color: var(--COLOR);
    display: inline;
    place-content: center;
}

.icon-button:hover,
.icon-button:focus-visible {
    transform: scale(1.2);
}

.icon-button[disabled] {
    visibility: hidden;
}

/* || GENERAL STYLES */

body {
    font-family: 'Nunito', sans-serif;
    min-height: 100vh;
    background-color: var(--BGCOLOR);
    color: var(--COLOR);
    background-image: url('./img/background.jpg');
    background-size: cover;
    background-position: top right;
    background-blend-mode: multiply;
}

input,
button,
textarea,
select {
    font: inherit;
}

#root {
    /* React root div */
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
}

a {
    text-decoration: none;
}

a:any-link {
    color: var(--LINK-COLOR);
}

a:hover,
a:focus-visible {
    color: var(--LINK-HOVER);
}

/* || PAGES */

.public,
.welcome {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.public {
    padding: 1em;
    flex-grow: 1;
}

.public__main,
.login {
    flex-grow: 1;
    border-top: 2px solid var(--BORDER-COLOR);
    border-bottom: 2px solid var(--BORDER-COLOR);
    padding: 1em 0;
}

.public__addr {
    margin-top: 1em;
}

.welcome a::before {
    content: "➜ "
}

/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--BGCOLOR);
    padding: 0 0.5em;
    border-bottom: 1px solid var(--BORDER-COLOR);
}

.dash-header__container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.dash-header__container--small {
    max-width: 800px;
}

.dash-header__nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 0.5em;
}

.dash-container {
    padding: 0.75em 0.5em 0.5em;
    flex-grow: 1;
}

.dash-footer {
    font-size: 1rem;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: var(--BGCOLOR);
    padding: 0.5em;
    border-top: 1px solid var(--BORDER-COLOR);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 1em;
}

.dash-footer__button {
    font-size: 1rem;
    width: auto;
    height: auto;
}

/* || TABLES - GENERAL */

thead,
tbody,
tr {
    display: contents;
}

.table {
    font-size: 0.91rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    color: var(--TABLE-COLOR);
    gap: 0.1em;
}

.table__button {
    padding: 0.25em;
    font-size: 1.5rem;
    color: var(--TABLE-COLOR);
}

.table__thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table__th,
.table__cell {
    background-color: var(--TABLE-BGCOLOR);
    text-align: left;
    border: 1px solid var(--TABLE-COLOR);
    padding: 0.5em;
}

.table__cell:last-child {
    display: grid;
    place-content: center;
}

.table__cell--inactive {
    background-color: var(--INACTIVE);
}

/* ||  NOTE */

.note__created,
.note__updated,
.note__username {
    display: none;
}

.note__status {
    font-weight: bold;
}

.note__status--open {
    color: var(--STATUS-OPEN);
}

.note__status--completed {
    color: var(--STATUS-COMPLETE);
}

/* ||  CRON */


.cron__status--active {
    color: var(--STATUS-ACTIVE);
}

.cron__status--inactive {
    color: var(--STATUS-INACTIVE);
}



/* || FORM - GENERAL */

.form {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.75em;
    max-width: 800px;
}

.form__checkbox-container {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.5em;
}

.form__persist {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5em;
}

.form__title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__input,
.form__submit-button {
    padding: 0.5em;
    border-radius: 15px;
}

.form__input--text {
    min-height: 150px;
}

.form__input--incomplete {
    border: 1px solid var(--INPUT-INVALID);
    outline: 1px solid var(--INPUT-INVALID);
}

.form__checkbox {
    width: 24px;
    height: 24px;
}

.form__select {
    width: fit-content;
    padding: 0.25em;
}

.form__action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    position: absolute;
    right: 0.5em;
}

.form__row {
    display: flex;
    flex-flow: row nowrap;
    gap: 2em;
}

.form__divider {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5em;
}

@media screen and (max-width: 400px) {
    h1 {
        font-size: 2rem;
    }

    .dash-header__nav {
        gap: 0;
    }

    .icon-button {
        font-size: 1.5rem;
    }
}


@media screen and (min-width: 768px) {

    body {
        font-size: 1.25rem;
    }

    .dash-header {
        padding: 0 1em;
    }

    .dash-container {
        padding: 1em;
    }

    .table {
        font-size: 1.5rem;
    }

    .table--notes {
        grid-template-columns: repeat(3, 1fr) 2fr repeat(2, 1fr);
    }

    .table--crons {
        font-size: 1rem;
        grid-template-columns: 1fr 5fr 5fr 1fr;
    }

    .note__created,
    .note__updated,
    .note__username {
        display: block
    }

    .form__action-buttons {
        position: static;
    }
}

.tt {
    background-color: white;
    animation-name: example;
    animation-duration: 2s;
}

@keyframes example {
    from {
        background-color: white;
    }

    to {
        background-color: #ccc;
    }
}



.changed {
    background-image: repeating-linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red, violet);
    background-size: 800% 800%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow 2s ease infinite;
}

@keyframes rainbow {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 25%
    }

    100% {
        background-position: 0% 50%
    }
}